.dev-tools-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.dev-tool {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-left: 3%;
  padding-right: 3%;
}

.dev-tool-logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.dev-tool-logo:hover {
  transform: scale(1.1);
}
@keyframes toolAnimation {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .dev-tool {
    width: 150px;
  }
}

@media screen and (max-width: 480px) {
  .dev-tool {
    width: 120px;
  }
}
